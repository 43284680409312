import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { StyleSheet, TouchableOpacity, View, } from 'react-native';
import { Subheading, useTheme } from 'react-native-paper';
import RenderHtml from 'react-native-render-html';
import { MaterialCommunityIcons } from '@expo/vector-icons';
export const FaqQuestion = (props) => {
    var _a, _b, _c, _d, _e, _f;
    const [expanded, setExpanded] = useState(false);
    const theme = useTheme();
    const styles = StyleSheet.create({
        questionStyle: {
            paddingHorizontal: 15,
        },
        questionTitleStyle: {
            flex: 10,
            fontSize: 18,
        },
        questionContainerStyle: {
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        answerTitleStyle: {
            color: theme.colors.onSurface,
            fontSize: 12,
            lineHeight: 20,
            marginVertical: 2,
            letterSpacing: 0.4,
        },
    });
    const [htmlContentWidth, setHtmlContentWidth] = useState(0);
    const onLayoutChangedEvent = (event) => {
        setHtmlContentWidth(event.nativeEvent.layout.width);
    };
    //eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const computedAnswerTextStyle = Object.assign(Object.assign({}, styles.answerTitleStyle), props.answerTitleStyle);
    return (_jsx(View, { style: [
            styles.questionStyle,
            expanded ? props.questionExpandedStyle : props.questionCollapsedStyle,
        ], onLayout: onLayoutChangedEvent, children: _jsxs(TouchableOpacity, { onPress: () => setExpanded(!expanded), children: [_jsxs(View, { style: styles.questionContainerStyle, children: [_jsx(Subheading, { style: [styles.questionTitleStyle, props.questionTitleStyle], children: props.question }), _jsx(MaterialCommunityIcons, { style: { flex: 1, alignItems: 'center', paddingLeft: 10 }, 
                            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            name: expanded
                                ? ((_a = props.expandedIcon) === null || _a === void 0 ? void 0 : _a.name) || 'chevron-down'
                                : ((_b = props.collapsedIcon) === null || _b === void 0 ? void 0 : _b.name) || 'chevron-up', 
                            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            color: expanded
                                ? ((_c = props.expandedIcon) === null || _c === void 0 ? void 0 : _c.color) || theme.colors.onSurface
                                : ((_d = props.collapsedIcon) === null || _d === void 0 ? void 0 : _d.color) || theme.colors.onSurface, size: expanded
                                ? ((_e = props.expandedIcon) === null || _e === void 0 ? void 0 : _e.size) || 24
                                : ((_f = props.collapsedIcon) === null || _f === void 0 ? void 0 : _f.size) || 24 })] }), expanded ? (_jsx(RenderHtml, { contentWidth: htmlContentWidth, baseStyle: computedAnswerTextStyle, source: { html: props.answer } })) : (_jsx(_Fragment, {}))] }) }));
};
