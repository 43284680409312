import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as ExpoCrypto from 'expo-crypto';
import { useEffect } from 'react';
import { TextInput, HelperText } from 'react-native-paper';
export const ValidatedInput = (props) => {
    var _a;
    useEffect(() => {
        if (props.value) {
            validate(props.value);
        }
    }, [props.value, props.reValidate]);
    const validate = (value) => {
        var _a;
        const fieldErrors = new Array();
        (_a = props.rules) === null || _a === void 0 ? void 0 : _a.forEach((validator) => {
            const error = validator(value);
            if (error) {
                fieldErrors.push(error);
            }
        });
        if (props.onValidate) {
            props.onValidate(fieldErrors);
        }
    };
    const onBlur = (event) => {
        validate(props.value);
        if (props.onBlur) {
            props.onBlur(event);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(TextInput, Object.assign({}, props, { mode: props.mode || 'outlined', error: props.errors && props.errors.length > 0, onBlur: onBlur })), !props.hideErrors &&
                ((_a = props.errors) === null || _a === void 0 ? void 0 : _a.map((error) => (_jsx(HelperText, { testID: `${props.testID}_errors`, type: 'error', style: props.errorsStyle, children: error }, ExpoCrypto.randomUUID()))))] }));
};
