import { jsx as _jsx } from "react/jsx-runtime";
import { StyleSheet, View, } from 'react-native';
import { MD2Colors } from 'react-native-paper';
import { Opacity, OpacityUtilities } from '../services';
export const Overlay = (props) => {
    const styles = StyleSheet.create({
        overlay: {
            top: 0,
            left: 0,
            position: 'absolute',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            height: props.height ? props.height : '100vh',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            width: props.width ? props.width : '100vw',
            backgroundColor: OpacityUtilities.getOpacityForColor(MD2Colors.grey500, Opacity.PERCENT_33),
        },
    });
    return (_jsx(View, { testID: props.testID, style: [styles.overlay, props.style], children: props.children }));
};
