import { jsx as _jsx } from "react/jsx-runtime";
import { TouchableOpacity, View } from 'react-native';
import { MD2Colors, useTheme } from 'react-native-paper';
import Animated, { Extrapolate, interpolate, useAnimatedStyle, } from 'react-native-reanimated';
export const CarouselPage = (props) => {
    const theme = useTheme();
    const backgroundColor = props.backgroundColor || MD2Colors.white;
    const color = props.color || theme.colors.secondary;
    const width = props.width || 10;
    const animStyle = useAnimatedStyle(() => {
        let inputRange = [
            props.pageIndex - 1,
            props.pageIndex,
            props.pageIndex + 1,
        ];
        let outputRange = [-width, 0, width];
        if (props.pageIndex === 0 &&
            props.selectProgression.value > props.pageCount - 1) {
            inputRange = [props.pageCount - 1, props.pageCount, props.pageCount + 1];
            outputRange = [-width, 0, width];
        }
        return {
            transform: [
                {
                    translateX: interpolate(props.selectProgression.value, inputRange, outputRange, Extrapolate.CLAMP),
                },
            ],
        };
    }, [props.selectProgression, props.pageIndex, props.pageCount]);
    const onSelected = () => {
        if (props.onSelected) {
            props.onSelected(props.pageIndex);
        }
    };
    return (_jsx(TouchableOpacity, { onPress: onSelected, children: _jsx(View, { style: {
                backgroundColor: backgroundColor,
                margin: 5,
                width: 10,
                height: 10,
                borderRadius: 50,
                overflow: 'hidden',
            }, children: _jsx(Animated.View, { style: [
                    {
                        borderRadius: 50,
                        backgroundColor: color,
                        flex: 1,
                    },
                    animStyle,
                ] }, props.pageIndex) }) }));
};
