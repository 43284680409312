import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useEffect, useRef, useState, } from 'react';
import { Animated, StyleSheet, View, } from 'react-native';
import { Divider, Text, TextInput, TouchableRipple, useTheme, } from 'react-native-paper';
import { Easing } from 'react-native-reanimated';
import { EmptyState } from '../EmptyState/EmptyState';
import { MaterialCommunityIcons } from '@expo/vector-icons';
export const DEFAULT_DROP_DOWN_ANIMATION_DURATION = 100;
export const DEFAULT_MAX_DROPDOWN_HEIGHT = 200;
export const DropDown = forwardRef((props, ref) => {
    const theme = useTheme();
    const styles = StyleSheet.create({
        item: {
            flex: 1,
            padding: 20,
            backgroundColor: theme.colors.surface,
        },
        highlightItem: {
            flex: 1,
            backgroundColor: theme.colors.primary,
            borderColor: theme.colors.secondary,
            borderWidth: 1,
            borderStyle: 'solid',
            borderRadius: 5,
        },
        highlightText: {
            color: theme.colors.onPrimary,
        },
        itemText: {
            color: theme.colors.onSurface,
        },
    });
    const [displayValue, setDisplayValue] = useState('');
    const [displayDropDown, setDisplayDropDown] = useState(props.isOpen || false);
    const [layout, setLayout] = useState();
    const [selectedItemIndex, setSelectedItemIndex] = useState();
    const [itemHeight, setItemHeight] = useState(0);
    const [dividerHeight, setDividerHeight] = useState(0);
    const maxHeight = props.maxDropDownHeight || DEFAULT_MAX_DROPDOWN_HEIGHT;
    const dropDownAnimated = props.animated || true;
    const dropDownAnimationDuration = dropDownAnimated
        ? props.animationDuration || DEFAULT_DROP_DOWN_ANIMATION_DURATION
        : 0;
    const dropDownHeight = useState(new Animated.Value(0))[0];
    const [dropdownItemHeight, setDropDownItemHeight] = useState(0);
    const [maxDropDownHeight, setMaxDropDownHeight] = useState(maxHeight);
    /*
     * The loading is only used to calculate the max height of the DropDown; this needs to be flipped to false once the
     * item height and divider height are acquired during the flat list item layout function; If not the flat list items are
     * tab indexable and the user gets "lost" when tabbing between controls;  This was necessary to collect the item layout
     * and also hide the flat list when not actively open
     */
    const [loading, setLoading] = useState(true);
    //eslint-disable-next-line
    // @ts-ignore
    const inputRef = useRef(null);
    const itemListRef = useRef(null);
    useEffect(() => {
        if (props.items && itemHeight > 0 && dividerHeight > 0) {
            const actualDropDownHeight = (itemHeight + dividerHeight) * props.items.length;
            setDropDownItemHeight(itemHeight + dividerHeight);
            setMaxDropDownHeight(maxHeight < actualDropDownHeight ? maxHeight : actualDropDownHeight);
            setLoading(false);
        }
    }, [props.items, itemHeight, dividerHeight]);
    useEffect(() => {
        props.isOpen ? openDropDown() : closeDropDown();
    }, [props.isOpen]);
    useEffect(() => {
        var _a;
        const toSelect = (_a = props.items) === null || _a === void 0 ? void 0 : _a.find((item) => item.value === props.value);
        if (toSelect)
            onDropDownChange(toSelect);
    }, [props.value]);
    useEffect(() => {
        var _a, _b;
        if ((((_a = props.items) === null || _a === void 0 ? void 0 : _a.length) || 0) > 1) {
            (_b = itemListRef === null || itemListRef === void 0 ? void 0 : itemListRef.current) === null || _b === void 0 ? void 0 : _b.scrollToIndex({
                animated: true,
                index: selectedItemIndex || 1,
            });
        }
    }, [selectedItemIndex]);
    const openDropDown = () => {
        Animated.timing(dropDownHeight, {
            toValue: maxDropDownHeight,
            duration: dropDownAnimationDuration,
            easing: Easing.linear,
            useNativeDriver: false,
        }).start();
        setDisplayDropDown(true);
        inputRef.current.focus();
    };
    const closeDropDown = () => {
        Animated.timing(dropDownHeight, {
            toValue: 0,
            duration: dropDownAnimationDuration,
            easing: Easing.linear,
            useNativeDriver: false,
        }).start();
        setDisplayDropDown(false);
        inputRef.current.focus();
    };
    const onDropDownPress = () => {
        if (props.isOpen && props.onDismiss) {
            props.onDismiss();
        }
        else if (!props.isOpen && props.onOpen) {
            props.onOpen();
        }
    };
    const onDropDownChange = (item) => {
        var _a;
        if (item) {
            setDisplayValue(item === null || item === void 0 ? void 0 : item.label);
            setSelectedItemIndex((_a = props === null || props === void 0 ? void 0 : props.items) === null || _a === void 0 ? void 0 : _a.indexOf(item));
        }
        else {
            setDisplayValue('');
            setSelectedItemIndex(undefined);
        }
        // props.onDismiss ? props.onDismiss() : null;
        // Call props.onChange with the selected item
        if (props.onChange) {
            props.onChange(item); // This ensures that the onChange callback is called
        }
        // Optionally dismiss the dropdown after selecting the item
        if (props.onDismiss) {
            props.onDismiss();
        }
    };
    const onDropDownItemPress = (item) => {
        onDropDownChange(item);
    };
    const onItemLayout = (event) => {
        setItemHeight(event.nativeEvent.layout.height);
    };
    const renderDropDownItem = (item) => {
        return (_jsx(TouchableRipple, { testID: `${props.testID}-dropdown-item-${item.index}`, onPress: () => onDropDownItemPress(item.item), onLayout: onItemLayout, children: _jsx(View, { testID: `${props.testID}-dropdown-item-view-${item.index}`, style: [
                    styles.item,
                    selectedItemIndex === item.index ? styles.highlightItem : {},
                ], children: _jsx(Text, { testID: `${props.testID}-dropdown-item-text-${item.index}`, style: [
                        selectedItemIndex === item.index
                            ? styles.highlightText
                            : styles.itemText,
                    ], children: item.item.label ? item.item.label : props.emptyMessage }) }) }));
    };
    const onLayout = (event) => {
        setLayout(event.nativeEvent.layout);
    };
    const onChangeText = (value) => {
        var _a;
        if (!displayDropDown && props.onOpen && value) {
            props.onOpen();
        }
        if (value) {
            const matchedItem = (_a = props.items) === null || _a === void 0 ? void 0 : _a.find((item) => item.label.toLowerCase().startsWith(value.toLowerCase()));
            if (matchedItem) {
                const matchedItemIndex = props.items.indexOf(matchedItem);
                setSelectedItemIndex(matchedItemIndex);
                setDisplayValue(value);
            }
        }
        else {
            onDropDownChange(undefined);
        }
    };
    const getDefaultItemLayout = (data, index) => {
        return {
            length: itemHeight,
            offset: dropdownItemHeight * (index - 1),
            index,
        };
    };
    const onDividerLayout = (event) => {
        if (!dividerHeight) {
            setDividerHeight(event.nativeEvent.layout.height);
        }
    };
    const renderSeparator = () => {
        return _jsx(Divider, { onLayout: onDividerLayout });
    };
    const onKeyPress = (event) => {
        var _a, _b;
        if (event.nativeEvent.key === 'Escape' && props.onDismiss) {
            props.onDismiss();
        }
        if (event.nativeEvent.key === 'Enter') {
            if (displayDropDown) {
                onDropDownChange(((_a = props.items) === null || _a === void 0 ? void 0 : _a.find((item, index) => index === selectedItemIndex)) ||
                    undefined);
            }
            else {
                onDropDownPress();
            }
        }
        if (event.nativeEvent.key === 'ArrowDown') {
            if (displayDropDown) {
                const newSelectionIndex = (selectedItemIndex === undefined ? -1 : selectedItemIndex) + 1;
                setSelectedItemIndex((((_b = props.items) === null || _b === void 0 ? void 0 : _b.length) || -1) > newSelectionIndex
                    ? newSelectionIndex
                    : selectedItemIndex);
            }
            else {
                onDropDownPress();
            }
        }
        if (event.nativeEvent.key === 'ArrowUp') {
            const newSelectionIndex = (selectedItemIndex || 0) - 1;
            setSelectedItemIndex(newSelectionIndex > -1 ? newSelectionIndex : selectedItemIndex);
        }
    };
    return (_jsxs(View, { ref: ref, style: [{ zIndex: 1, margin: 3 }, props.style], testID: `${props.testID}`, onLayout: onLayout, children: [_jsx(TextInput, { ref: inputRef, style: props.style, testID: `${props.testID}-input`, mode: props.mode || 'outlined', label: props.label, placeholder: props.placeholder, value: displayValue, onPointerUp: onDropDownPress, onChangeText: onChangeText, onKeyPress: onKeyPress, right: _jsx(TextInput.Icon, { testID: `${props.testID}-text-input-icon`, onPress: () => {
                        onDropDownPress();
                    }, icon: () => (_jsx(MaterialCommunityIcons, { testID: `${props.testID}-icon`, name: props.isOpen ? 'menu-up' : 'menu-down', size: 24, color: theme.colors.onSurface, onPress: onDropDownPress })) }) }), (loading || props.isOpen) && (_jsx(Animated.FlatList, { testID: `${props.testID}-flat-list`, ref: itemListRef, data: props.items, initialScrollIndex: selectedItemIndex, ItemSeparatorComponent: renderSeparator, ListEmptyComponent: EmptyState({
                    text: props.emptyMessage || 'No items found',
                }), renderItem: renderDropDownItem, getItemLayout: getDefaultItemLayout, style: [
                    {
                        flex: 1,
                        shadowColor: theme.colors.shadow,
                        shadowRadius: 2,
                        borderColor: theme.colors.primary,
                        borderWidth: displayDropDown ? 2 : 0,
                        borderTopWidth: 0,
                        borderBottomLeftRadius: 5,
                        borderBottomRightRadius: 5,
                        backgroundColor: theme.colors.surfaceVariant,
                        maxHeight: maxHeight,
                        height: dropDownHeight.interpolate({
                            inputRange: [0, maxDropDownHeight],
                            outputRange: [0, maxDropDownHeight],
                        }),
                        position: 'absolute',
                        top: (layout === null || layout === void 0 ? void 0 : layout.height) || 0,
                        width: layout === null || layout === void 0 ? void 0 : layout.width,
                        zIndex: 2,
                    },
                    props.dropdownStyle,
                    props.position === 'top' ? { top: 'auto', bottom: '100%' } : {},
                ] }))] }));
});
