var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { LuminateRuntimeException } from '@luminate/luminate-ts-sdk';
import * as SecureStore from 'expo-secure-store';
import Cookies from 'js-cookie';
import { PlatformUtils } from './PlatformUtils';
export class StorageService {
    constructor() {
        this.getValueFromStorage = (key) => __awaiter(this, void 0, void 0, function* () {
            let keyValue;
            if (!PlatformUtils.isWeb()) {
                try {
                    keyValue = yield SecureStore.getItemAsync(key);
                }
                catch (ex) {
                    throw new LuminateRuntimeException(`Unable to retrieve ${key} from storage.`, ex);
                }
            }
            else {
                keyValue = Cookies.get(key);
            }
            return keyValue === undefined || keyValue === null ? undefined : keyValue;
        });
        this.getObjectFromStorage = (key) => __awaiter(this, void 0, void 0, function* () {
            const keyValue = yield this.getValueFromStorage(key);
            return keyValue ? JSON.parse(keyValue) : undefined;
        });
        this.getStringFromStorage = (key) => __awaiter(this, void 0, void 0, function* () {
            return yield this.getValueFromStorage(key);
        });
        this.getNumberFromStorage = (key, radix) => __awaiter(this, void 0, void 0, function* () {
            const keyValue = yield this.getValueFromStorage(key);
            return keyValue ? parseInt(keyValue, radix) : undefined;
        });
        this.getBooleanFromStorage = (key, defaultValue) => __awaiter(this, void 0, void 0, function* () {
            try {
                const keyValue = yield this.getValueFromStorage(key);
                return keyValue ? JSON.parse(keyValue) : defaultValue;
            }
            catch (ex) {
                throw new LuminateRuntimeException(`Unable to retrieve boolean ${key} from storage.`, ex);
            }
        });
        this.addToStorage = (key, value) => __awaiter(this, void 0, void 0, function* () {
            let keyValue;
            if (typeof value !== 'string') {
                keyValue = JSON.stringify(value);
            }
            else {
                keyValue = value;
            }
            if (!PlatformUtils.isWeb()) {
                yield SecureStore.setItemAsync(key, keyValue);
            }
            else {
                Cookies.set(key, keyValue);
            }
        });
        this.removeFromStorage = (key) => __awaiter(this, void 0, void 0, function* () {
            if (!PlatformUtils.isWeb()) {
                yield SecureStore.deleteItemAsync(key);
            }
            else {
                Cookies.remove(key);
            }
        });
    }
}
StorageService.create = () => {
    return new StorageService();
};
