import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { View } from 'react-native';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
export const UnorderedItem = (props) => {
    return (_jsx(View, { children: _jsxs(View, { style: { flex: 1, flexDirection: 'row', marginLeft: 20 }, children: [_jsx(MaterialIcons, { style: {
                        position: 'absolute',
                        top: 15,
                        left: 0,
                        paddingRight: 20,
                        marginRight: 20,
                    }, size: props.size ? props.size : 6, 
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    name: props.icon ? props.icon : 'circle' }), _jsx(View, { style: { flex: 1, marginLeft: 20 }, children: props.children })] }) }));
};
