import { jsx as _jsx } from "react/jsx-runtime";
import { View } from 'react-native';
export const Outline = (props) => (_jsx(View, { testID: props.testID, pointerEvents: 'none', style: [
        {
            position: 'absolute',
            left: 0,
            right: 0,
            top: 6,
            bottom: 0,
        },
        {
            backgroundColor: props.backgroundColor,
            borderRadius: props.roundness,
            borderWidth: (props.isV3 ? props.hasActiveOutline : props.focused)
                ? 2
                : 1,
            borderColor: props.hasActiveOutline
                ? props.activeColor
                : props.outlineColor,
        },
        props.style,
    ] }));
