import { jsx as _jsx } from "react/jsx-runtime";
import { A } from '@expo/html-elements';
import { StyleSheet } from 'react-native';
import { useTheme } from 'react-native-paper';
export const ExternalLink = (props) => {
    const theme = useTheme();
    const displayText = props.displayText || props.url;
    const styles = StyleSheet.create({
        textStyle: {
            textDecorationLine: 'underline',
            color: theme.colors.primary,
        },
    });
    return (
    //eslint-disable-next-line
    // @ts-ignore
    _jsx(A, { testID: props.testID, style: [styles.textStyle, props.style], href: props.url, children: displayText }));
};
