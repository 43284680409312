import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LinearGradient } from 'expo-linear-gradient';
import { useRef, useState } from 'react';
import { ImageBackground, StyleSheet, View, } from 'react-native';
import { Button, Headline, IconButton, MD2Colors, useTheme, } from 'react-native-paper';
import { useSharedValue } from 'react-native-reanimated';
import ReactNativeCarousel from 'react-native-reanimated-carousel';
import { CarouselPage } from '../CarouselPage';
import { HtmlView } from '../HtmlView';
export const Carousel = (props) => {
    var _a, _b;
    const theme = useTheme();
    const carouselRef = useRef(null);
    const [autoPlayEnabled, setAutoPlayEnabled] = useState((((_a = props.items) === null || _a === void 0 ? void 0 : _a.length) || 0) > 1);
    const [carouselWidth, setCarouselWidth] = useState(1);
    const [carouselHeight, setCarouselHeight] = useState(1);
    const selectionProgress = useSharedValue(0);
    const autoPlayInterval = props.autoPlayInterval || 6000;
    const scrollAnimationDuration = props.scrollAnimationDuration || 2000;
    const maxWidth = props.maxWidth || 1024;
    const controlPanelOffset = props.controlPanelOffset || 50;
    const styles = StyleSheet.create({
        fontColor: {
            color: MD2Colors.white,
        },
    });
    const renderCarouselItem = (item) => {
        var _a;
        return (_jsx(View, { style: { flex: 1, justifyContent: 'center', alignItems: 'center' }, children: _jsxs(ImageBackground, { style: { flex: 1, maxWidth: maxWidth, width: '100%', height: '100%' }, source: { uri: item.item.imageUrl }, defaultSource: props.defaultSource, resizeMode: 'cover', children: [(item.item.title || item.item.description || item.item.action) && (_jsx(LinearGradient, { style: {
                            height: '100%',
                            zIndex: 2,
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: 0,
                        }, colors: [theme.colors.primary, MD2Colors.transparent], start: { x: 0, y: 0 }, end: { x: 1, y: 0 } })), _jsx(View, { style: { flex: 1, zIndex: 3 }, children: _jsxs(View, { style: {
                                flex: 1,
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }, children: [_jsx(View, { style: {
                                        width: 55,
                                        margin: 10,
                                    }, children: _jsx(IconButton, { mode: 'contained-tonal', size: 24, icon: 'less-than', testID: `previous-button-${item.item.id}`, onPress: movePrevious }) }), _jsx(View, { style: { flex: 1 }, children: _jsxs(View, { style: { flex: 1, maxWidth: 400 }, children: [_jsx(Headline, { style: [styles.fontColor], children: item.item.title }), _jsx(HtmlView, { style: [styles.fontColor], children: item.item.description }), item.item.action && (_jsx(Button, { style: { marginVertical: 5, maxWidth: 275 }, mode: 'contained', testID: `action-button-${item.item.id}`, onPress: () => {
                                                    onActionLinkClicked(item.item);
                                                }, children: ((_a = item.item.action) === null || _a === void 0 ? void 0 : _a.label) || 'Learn More' }))] }) }), _jsx(View, { style: {
                                        width: 55,
                                        margin: 10,
                                    }, children: _jsx(IconButton, { mode: 'contained-tonal', icon: 'greater-than', testID: `next-button-${item.item.id}`, onPress: moveNext }) })] }) })] }) }));
    };
    const toggleAutoPlay = () => {
        setAutoPlayEnabled(!autoPlayEnabled);
    };
    const onActionLinkClicked = (item) => {
        if (props.onActionLinkClicked) {
            props.onActionLinkClicked(item);
        }
    };
    const moveNext = () => {
        var _a;
        if (carouselRef.current) {
            setAutoPlayEnabled(false);
            (_a = carouselRef.current) === null || _a === void 0 ? void 0 : _a.next();
        }
    };
    const movePrevious = () => {
        var _a;
        if (carouselRef.current) {
            setAutoPlayEnabled(false);
            (_a = carouselRef.current) === null || _a === void 0 ? void 0 : _a.prev();
        }
    };
    const onImageLayoutChanged = (event) => {
        setCarouselWidth((event.nativeEvent.layout.width || 0) > 0
            ? event.nativeEvent.layout.width
            : 1);
        setCarouselHeight((event.nativeEvent.layout.height || 0) > 0
            ? event.nativeEvent.layout.height
            : 1);
    };
    const onProgressChange = (offsetProgress, absoluteProgress) => {
        selectionProgress.value = absoluteProgress;
    };
    const onCarouselPageSelected = (selectedIndex) => {
        var _a;
        setAutoPlayEnabled(false);
        (_a = carouselRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo({ index: selectedIndex, animated: true });
    };
    return (_jsxs(View, { style: [props.style], onLayout: onImageLayoutChanged, children: [_jsx(ReactNativeCarousel, { ref: carouselRef, loop: true, autoPlayInterval: autoPlayInterval, scrollAnimationDuration: scrollAnimationDuration, width: carouselWidth, height: carouselHeight, vertical: false, pagingEnabled: true, autoPlay: autoPlayEnabled, snapEnabled: true, data: props.items || [], mode: 'parallax', modeConfig: {
                    parallaxScrollingScale: 1,
                    parallaxScrollingOffset: 0,
                }, renderItem: renderCarouselItem, onProgressChange: onProgressChange }), _jsx(View, { style: {
                    position: 'absolute',
                    top: carouselHeight - controlPanelOffset,
                    left: 0,
                    width: carouselWidth,
                    height: controlPanelOffset,
                    flexDirection: 'column-reverse',
                    alignItems: 'center',
                }, children: _jsxs(View, { style: {
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }, children: [_jsx(IconButton, { mode: 'contained-tonal', icon: autoPlayEnabled ? 'pause' : 'play', size: 12, testID: 'play-pause-button', onPress: toggleAutoPlay }), (_b = props.items) === null || _b === void 0 ? void 0 : _b.map((item, index) => {
                            var _a;
                            return (_jsx(CarouselPage, { pageIndex: index, pageCount: ((_a = props.items) === null || _a === void 0 ? void 0 : _a.length) || 0, selectProgression: selectionProgress, onSelected: onCarouselPageSelected }, `carousel-page-${index}`));
                        })] }) })] }));
};
